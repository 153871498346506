import React from "react";
import styles from "./Footer.module.css";
import { NavLink } from "react-router-dom";
import { text } from "../../../components/text";

function Footer() {
  return (
    <div className={styles.footerContainer}>
      {/* link to other pages */}
      <div className={styles.buttonsContainer}>
        <div className={styles.button}>
          <NavLink to="/schedule" className={styles.link}>
            <p>Schedule Estimate</p>
          </NavLink>
        </div>
        <div className={styles.button}>
          <NavLink to="/reviews" className={styles.link}>
            <p>Give A Review</p>
          </NavLink>
        </div>
      </div>
      {/* just contact info */}
      <div className={styles.contactInfo}>
        <p>{text.contactInfo.phone}</p>
        <a href="https://www.facebook.com/ppppainting/">Facebook</a>
      </div>
      <p className={styles.subtitle}>© 2024 Patricks Picture Perfect Painting LLC all rights reserved</p>
    </div>
  );
}

export default Footer;
