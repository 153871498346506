import React from "react";
import styles from "./Card.module.css"

function Card({title, content, image, imageStyle, paragraphStyling, containerStyling}){
    return(
        <div className={styles.container} style={containerStyling}>
            <h3>{title}</h3>
            <img src={image} alt="" className={styles.image} style={imageStyle} />
            <p style={paragraphStyling}>{content}</p>
        </div>
    )
}

export default Card;
