import React from "react";
import styles from "./WorkWeDo.module.css";
import Card from "../../../components/Card/Card";
import { text } from "../../../components/text";

function WorkWeDo() {
  return (
    <div className={styles.container}>
      <div>
        <Card image="https://ik.imagekit.io/8h7rb1fth/paintingSample.png?updatedAt=1711401232611" title={text.WorkWeDo.card1.header} content={text.WorkWeDo.card1.content} />
        <Card image="https://ik.imagekit.io/8h7rb1fth/stainingSample.png?updatedAt=1711401232837" title={text.WorkWeDo.card2.header} content={text.WorkWeDo.card2.content} />
        <Card image="https://ik.imagekit.io/8h7rb1fth/epoxySample.jpg?updatedAt=1711402169626" title={text.WorkWeDo.card3.header} content={text.WorkWeDo.card3.content} />
      </div>
    </div>
  );
}

export default WorkWeDo;
