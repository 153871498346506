import React from "react";
import WorkWeDo from "./WorkWeDo/WorkWeDo";
import PastWork from "./PastWork/PastWork";
import WhyAreWeBetter from "./WhyAreWeBetter/WhyareWeBetter";
import Reviews from "./Reviews/Reviews";
import styles from "./Home.module.css";
import AboutUs from "./AboutUs/AboutUs";

function Home() {
  const mainFiller="https://ik.imagekit.io/8h7rb1fth/MainFiller.jpg?updatedAt=1711401221549"
  return (
    <>
      <div className={styles.mainImageContainer}>
        <img src={mainFiller} alt="" className={styles.MainImage} />
      </div>
      <AboutUs />
      <WorkWeDo />
      <PastWork
        images={[
          "display1/item1.png",
          "display1/item2.png",
          "display1/item3.png",
          "display1/item4.png",
          "display1/item5.png",
          "display1/item6.png",
          "display1/item7.png",
          "display1/item8.png",
          "display1/item9.png",
          "display1/item10.png",
        ]}
      />
      <WhyAreWeBetter />
      <div className={styles.pastWorkContainer}>
        <PastWork
          images={[
            "display2/item1.png",
            "display2/item2.png",
            "display2/item3.png",
            "display2/item4.png",
            "display2/item5.png",
            "display2/item6.png",
            "display2/item7.png",
            "display2/item8.png",
            "display2/item9.png",
            "display2/item10.png",
          ]}
        />
      </div>
      <Reviews />
    </>
  );
}

export default Home;
