import React from "react";
import styles from "./WhyAreWeBetter.module.css";
import Card from "../../../components/Card/Card";
import { text } from "../../../components/text";

function WhyAreWeBetter() {
  return (
    <div className={styles.sectionContainer}>
      <h2>{text.WhatMakesUsDifferent.mainHeader}</h2>
      <div>
        <Card image="https://ik.imagekit.io/8h7rb1fth/serviceSample.png?updatedAt=1711401229692" title={text.WhatMakesUsDifferent.card1.header} content={text.WhatMakesUsDifferent.card1.content} />
        {text.WhatMakesUsDifferent.card2.content.length<1 && <Card image="https://ik.imagekit.io/8h7rb1fth/estimateFiller(1).jpg?updatedAt=1711403415559" title={text.WhatMakesUsDifferent.card2.header} content={text.WhatMakesUsDifferent.card2.content}  imageStyle={{height: 100 + "%", width: 130 + "%"}} paragraphStyling={{height: 0, padding: 0}} containerStyling={{padding: 0, height: 21+"rem", overflow: "hidden"}} />}
        {text.WhatMakesUsDifferent.card2.content.length>0 && <Card image="https://ik.imagekit.io/8h7rb1fth/estimateFiller(1).jpg?updatedAt=1711403415559" title={text.WhatMakesUsDifferent.card2.header} content={text.WhatMakesUsDifferent.card2.content}   />}
        <Card image="https://ik.imagekit.io/8h7rb1fth/sherwinWilliamsLogo.png?updatedAt=1711503816701" title={text.WhatMakesUsDifferent.card3.header} content={text.WhatMakesUsDifferent.card3.content} />
      </div>
    </div>
  );
}

export default WhyAreWeBetter;
