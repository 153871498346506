import React from "react";
import styles from "./AboutUs.module.css";
import { text } from "../../../components/text";

function AboutUs() {
  return (
    <div className={styles.container}>
      <img src="https://ik.imagekit.io/8h7rb1fth/teamFiller.jpg?updatedAt=1711401221296" alt="" className={styles.image} />
      <div className={styles.textContainer}>
        <h2>{text.AboutUs.header}</h2>
        <h4>{text.AboutUs.subtitle}</h4>
        <p>
          {text.AboutUs.content}
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
