

export const text={
    AboutUs:{
        header: "About Us",
        subtitle: "Painting & Refinishing",
        content: "Patrick's picture perfect painting!! We care about your home. We strive to give quality work with the best Product. We make you feel like family, and your home will be in good hands, with an honest and fair price."
    },
    WorkWeDo: {
        card1:{
            header: "Painting",
            content: "We provide full Painting Services for the interior and exterior of your home. We specialize in cabinets, doors, handrails, mantels, and full restoration."
        },
        card2:{
            header: "Staining",
            content: "Helps preserve wood fixtures, and makes them look clean and neat to reinforce an orderly yet rustic appearance."
        },
        card3:{
            header: "Epoxies",
            content: "A finishing for cement which makes the cement more durable, and adds traction to your floor."
        }
    },
    WhatMakesUsDifferent: {
        mainHeader: "Why Choose US?",
        card1:{
            header: "Excellent Service",
            content: "We are committed to making sure your painting needs are met. We love what we do, you are not just another paycheck to us. We are always sure the job is done to perfection and the way you want it."
        },
        card2:{
            header: "Free Estimates",
            content: ""
        },
        card3:{
            header: "Quality paint",
            content: "We pride ourselves on using the top of the line paint. We want your paint and stain to last a lifetime. By using brands such as Sherwin Williams Emerald products."
        }
    },
    contactInfo:{
        phone: "435-236-4517"
    }
}
